import React from 'react'
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby'
import { Grid } from '@material-ui/core';
import { getImage } from 'gatsby-plugin-image';

import Layout from '../components/layout'
import CardLink from '../components/CardLink/cardLink';
import Hero from '../components/Hero';
import Subtitle from '../components/Subtitle';

import useStyles from '../pagesStyles/actualitesStyles';

const Installation = ({ data }) => {
  const classes = useStyles();
  const md = data.markdownRemark.frontmatter

  const articles = data.installation.edges;

  const renderArticles = () => {
    return articles.map((article, key) => {
      const articleInfos = article?.node;
      const articleImage = getImage(articleInfos?.image?.gatsbyImageData);
      // If the article's image was not set, use the same image as the first image found in the article's content
      const imageFromContent = getImage(articleInfos?.content?.references.find(ref => ref?.gatsbyImageData)?.gatsbyImageData);
      const image = articleImage || imageFromContent;

      return <CardLink
        key={key}
        title={articleInfos.title.title}
        image={image}
        link={`/installation/${articleInfos.slug}`}
        isOutsideLink={articleInfos.adherent ?? false}
      />
    })
  }

  return (
    <Layout>
      <Helmet>
        <html lang='fr' />
        <title>{md.SEOtitle}</title>
        <meta name='description' content={md.SEOdescription} />
      </Helmet>
      <Hero image={md.coverImage.childImageSharp.gatsbyImageData} title={md.title} alt={md.alt} />
      <Subtitle text={md.paragraph} />
      <Grid container className={classes.container}>
        {renderArticles()}
      </Grid>
    </Layout>
  );
}

export default Installation

export const query = graphql`
  query {
    installation: allContentfulInstallation(sort: {fields: createdAt, order: ASC}) {
      edges {
        node {
          slug
          description {
            description
          }
          title {
            title
          }
          image {
            gatsbyImageData(height: 200, width: 300, placeholder: BLURRED)
          }
          content {
            raw
            references {
              gatsbyImageData(height: 200, width: 300, placeholder: BLURRED)
            }
          }
        }
      }
    },
    markdownRemark(fileAbsolutePath: {regex: "/installation.md/"}) {
      frontmatter {
        SEOtitle
        SEOdescription
        coverImage {
          childImageSharp {
              gatsbyImageData(height: 700, placeholder: BLURRED)
            }
          }
        alt
        title
        paragraph
      }
    }
  }
`