import React from 'react';
import styles from './styles';
import PropTypes from 'prop-types';
import { Box, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { GatsbyImage } from 'gatsby-plugin-image';

import CustomButton from '../../components/CustomButton/customButton';

const CardLink = ({ title, image, description, link, isOutsideLink }) => {
  const classes = styles({ description });

  return (
    <Card className={classes.root} elevation={0}>
      <CardContent className={classes.cardTitleBox}>
        <Typography gutterBottom variant='h5' component='h2' className={classes.cardTitle}>
          {title}
        </Typography>
      </CardContent>

      <div className={classes.cardWrapper}>
        <CardContent>
          <GatsbyImage image={image} loading='eager' alt={title} />
          <Box className={classes.textBox}>
            <Typography variant='body2' color='textPrimary' component='p'>
              {description}
            </Typography>
          </Box>
        </CardContent>
        <CardActions>
          <CustomButton link={link} isOutsideLink={isOutsideLink} text='Lire la suite' className={classes.cardButton} />
        </CardActions>
      </div>
    </Card>
  );
};

export default CardLink;

CardLink.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  description: PropTypes.string,
  link: PropTypes.string.isRequired,
  isOutsideLink: PropTypes.bool,
};

CardLink.defaultProps = {
  isOutsideLink: false,
};
