import makeStyles from '@material-ui/core/styles/makeStyles';
export default makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 10,
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardTitleBox: {
    display: 'flex',
    alignItems: 'center',
  },
  cardTitle: {
    textTransform: 'uppercase',
    fontSize: 25,
    fontWeight: 600,
    textAlign: 'center',
    lineHeight: 1.2,
  },
  textBox: {
    height: ({ description }) => (description ? 144 : 54),
    justifyContent: 'center',
    padding: 24,
    '@media (max-width:890px)': {
      height: ({ description }) => (description ? 80 : 0),
      padding: 0
    },
  },
  cardImage: {
    width: '30%',
  },
  cardButton: {
    width: '10%'
  }
}));